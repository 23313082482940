import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 3rem 0 0;

  h2 {
    font-family: 'Amaranth';
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#212121')};
    text-align: center;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }
`;

export const Grid = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8fr;
  gap: 2rem 2rem;

  @media (max-width: 960px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 680px) {
    grid-template-columns: 1fr;
  }
`;

export const Item = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.11);

  &:hover {
    box-shadow: ${({ theme }) =>
      theme === 'light'
        ? '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
        : '0 20px 25px -5px rgba(24,23,23,1), 0 10px 10px -5px rgba(24,23,23,1)'};
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-property: box-shadow;
    transition-property: box-shadow;
  }

  h4 {
    color: ${({ theme }) => (theme === 'light' ? '#212121' : '#fff')};
  }

  p {
    color: ${({ theme }) => (theme === 'light' ? '#707070' : '#c7c7c7')};
  }
`;

export const ImgWrapper = styled.div`
  height: 200px;
  overflow: hidden;
`;

export const Description = styled.p`
  padding: 0.5rem 0;
  margin: 0.5rem 0 0;
  font-size: 18px;
`;

export const Content = styled.div`
  div {
    padding-bottom: 0.5rem;
  }

  a {
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
  }

  a:hover {
    color: #6455c8;
  }

  span {
    font-weight: bold;
  }
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    &:first-child {
      margin-right: 0.5rem;
    }

    img {
      margin: 0;
    }

    svg path {
      fill: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    }

    span {
      color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
      margin-left: 0.5rem;
    }
  }
`;

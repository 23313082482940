import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import { Wrapper, DevWrapper, Details, Icon, Skills } from './styles';
import dev from './dev.json';

export const Dev = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="dev">
      <DevWrapper as={Container}>
        <Details theme={theme}>
          <h2>Dev</h2>
          <div>
            <Row>
              {dev.map(({ id, group, icon, iconAlt, devSkills }) => (
                <Col key={id} xs={6} sm={6} md={4} lg={4}>
                  <Skills theme={theme}>
                    <Icon theme={theme}>
                      <img width="55" src={icon} alt={iconAlt} />
                    </Icon>
                    <h3>{group}</h3>
                    <ul>
                      {devSkills.map(x => (
                        <li key={x}>{x}</li>
                      ))}
                    </ul>
                  </Skills>
                </Col>
              ))}
            </Row>
          </div>
        </Details>
      </DevWrapper>
    </Wrapper>
  );
};

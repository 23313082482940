if (typeof window !== 'undefined') {
  window.onscroll = function() {
    const scrollBackground = document.getElementById('scrollBackground');
    if (scrollBackground) {
      const scroll = window.scrollY;

      if (scroll >= 5) {
        scrollBackground.classList.add('solid');
      } else {
        scrollBackground.classList.remove('solid');
      }
    }
  };
}

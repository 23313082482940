import React, { useContext } from 'react';
import { Nav, Row, Col, Tab } from 'react-bootstrap';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container } from 'components/common';
import { Details, AboutWrapper, ButtonWrapper, Quote } from './styles';

export const About = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <AboutWrapper as={Container} id="about">
      <Details theme={theme}>
        <h2>About</h2>
        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
          <Col sm={12}>
            <Nav className="flex-row justify-content-center">
              <Nav.Item>
                <ButtonWrapper theme={theme}>
                  <Nav.Link eventKey="first">
                    <img width="55" src="/icons/profile.png" alt="" />
                    <div>About</div>
                  </Nav.Link>
                </ButtonWrapper>
              </Nav.Item>
              <Nav.Item>
                <ButtonWrapper theme={theme}>
                  <Nav.Link eventKey="second">
                    <img width="55" src="/icons/computer.png" alt="" />
                    <div>Web Dev</div>
                  </Nav.Link>
                </ButtonWrapper>
              </Nav.Item>
              <Nav.Item>
                <ButtonWrapper theme={theme}>
                  <Nav.Link eventKey="third">
                    <img width="55" src="/icons/books.png" alt="" />
                    <div>School</div>
                  </Nav.Link>
                </ButtonWrapper>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={12}>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                <Row>
                  <Col md={4}>
                    <Quote theme={theme}>
                      <p>The people who are crazy enough to think they can change the world are the ones who do.</p>
                      <span>&mdash; Steve Jobs</span>
                    </Quote>
                  </Col>
                  <Col md={8}>
                    <span>
                      Hi, I’m Jeannie - my life is a big adventure. I strive to do meaningful work and I want to change
                      the world for the better, even if it’s just for one person. I’m a forever student, there's so much
                      to learn! You can find me practicing a strength sport (currently: powerlifting + bouldering),
                      building an app or website, or traveling and eating good food.
                    </span>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="second">
                <Row>
                  <Col md={4}>
                    <Quote theme={theme}>
                      <p>
                        Full Stack Web Developer, <br /> Full-Time Creative.
                      </p>
                    </Quote>
                  </Col>
                  <Col md={8}>
                    <span>
                      As a kid, I loved putting together puzzles and building with Legos. I started coding after
                      college, but I've been an engineer since day one. These days, I exercise my creative side by
                      bringing ideas to the screen through code. It means a lot to me to be able to work in a diverse
                      team setting. Outside of my job, my experience includes personal projects and freelance work.
                    </span>
                  </Col>
                </Row>
              </Tab.Pane>
              <Tab.Pane eventKey="third">
                <Row>
                  <Col md={4}>
                    <Quote theme={theme}>
                      <p>Got a Bachelor's in Business Economics in 2015, learned web development in 2018.</p>
                    </Quote>
                  </Col>
                  <Col md={8}>
                    <span>
                      Having a background in Business Economics, I am an detailed and observant thinker with the ability
                      to learn by identifying patterns and making new connections. I've learned to learn fast. My
                      knowledge expands to web development. My experiences, curiosity, and drive to learn and build on
                      my skills make me a versatile software engineer.
                    </span>
                  </Col>
                </Row>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Tab.Container>
      </Details>
    </AboutWrapper>
  );
};

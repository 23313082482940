import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card } from 'components/common';
import Img from 'gatsby-image';
import { Wrapper, Grid, Item, ImgWrapper, Description, Content } from './styles';
import projects from './projects.json';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);

  const projectImages = useStaticQuery(graphql`
    query {
      audioLineUp: file(relativePath: { eq: "screenshot-audiolineup.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      changeTheBias: file(relativePath: { eq: "screenshot-change-the-bias.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tinyVersusBot: file(relativePath: { eq: "screenshot-tiny-versus-bot.png" }) {
        childImageSharp {
          fluid(maxWidth: 500, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const projectImgVars = [
    projectImages.audioLineUp.childImageSharp.fluid,
    projectImages.changeTheBias.childImageSharp.fluid,
    projectImages.tinyVersusBot.childImageSharp.fluid,
  ];

  return (
    <Wrapper as={Container} theme={theme} id="projects">
      <h2>Projects</h2>
      <Grid>
        {projects.map(({ id, name, website, description }) => (
          <Item key={id} theme={theme}>
            <Card theme={theme}>
              <Content theme={theme}>
                <div>
                  <ImgWrapper>
                    <Img fluid={projectImgVars[id]} />
                  </ImgWrapper>
                  <Description>
                    <span>{name}</span> {description}
                  </Description>
                  &#8212;{' '}
                  <a theme={theme} href={website}>
                    {website}
                  </a>
                </div>
              </Content>
            </Card>
          </Item>
        ))}
      </Grid>
    </Wrapper>
  );
};

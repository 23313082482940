import styled from 'styled-components';
import detailsIllustration from 'assets/illustrations/details.svg';

export const Wrapper = styled.div`
  background-image: url(${detailsIllustration});
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`;

export const AboutWrapper = styled.div`
  padding: 3rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding: 0;

  h2 {
    font-family: 'Amaranth';
    margin-bottom: 2rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#212121')};
    text-align: center;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  span {
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  .nav-item {
    text-transform: uppercase;
  }

  .tab-content {
    margin-top: 25px;
  }

  @media (max-width: 960px) {
    padding-left: unset;
    width: 100%;
  }

  @media (max-width: 767px) {
    .col-md-4 {
      margin-bottom: 20px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  text-align: center;

  .nav-link {
    opacity: 0.5;
    width: 115px;
    padding: 0.5rem 0;
  }

  .nav-link.active {
    opacity: 1;
  }

  a,
  a:hover {
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
    opacity: 1;
  }

  img {
    filter: ${({ theme }) => (theme === 'light' ? 'none' : 'invert(100%);')};
    -webkit-filter: ${({ theme }) => (theme === 'light' ? 'none' : 'invert(100%);')};
    margin-bottom: 10px;
  }

  @media (max-width: 960px) {
    mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
  }

  @media (min-width: 769px) {
    .nav-link {
      margin: 0 1rem;
    }
  }

  @media (max-width: 425px) {
    .nav-link {
      width: 100px;
    }
  }
`;

export const Quote = styled.div`
  p {
    font-size: 23px;
    margin: 0 0 10px;
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
  }
  span {
    text-transform: uppercase;
    color: ${({ theme }) => (theme === 'light' ? '#000' : '#fff')};
  }

  @media (max-width: 960px) {
    mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 2rem;
  }

  img {
    width: 100%;
  }
`;

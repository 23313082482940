import styled from 'styled-components';
import detailsIllustration from 'assets/illustrations/details.svg';

export const Wrapper = styled.div`
  background-image: url(${detailsIllustration});
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
`;

export const DevWrapper = styled.div`
  padding: 3rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 960px) {
    flex-direction: column;
  }
`;

export const Details = styled.div`
  flex: 1;
  padding-left: 2rem;

  @media (max-width: 960px) {
    padding-left: unset;
    text-align: center;
    width: 100%;
  }

  h2 {
    font-family: 'Amaranth';
    margin-bottom: 1rem;
    font-size: 26pt;
    color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#000')};
    text-align: center;

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  h3 {
    font-family: 'Amaranth';
    margin: 20px 0;
    text-align: center;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 2.5rem;
    font-size: 20pt;
    font-weight: normal;
    line-height: 1.3;
    color: ${({ theme }) => (theme === 'dark' ? '#c7c7c7' : '#000')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }

  hr {
    background-color: ${({ theme }) => (theme === 'dark' ? '#c7c7c7' : '#000')};

    @media (max-width: 960px) {
      mix-blend-mode: ${({ theme }) => (theme === 'light' ? 'unset' : 'difference')};
    }
  }
`;

export const Icon = styled.div`
  text-align: center;

  img {
    filter: ${({ theme }) => (theme === 'light' ? 'none' : 'invert(100%);')};
    -webkit-filter: ${({ theme }) => (theme === 'light' ? 'none' : 'invert(100%);')};
    margin: 0;
  }
`;

export const Skills = styled.div`
  padding: 20px 0;

  @media (max-width: 960px) {
    ul {
      text-align: left;
      color: ${({ theme }) => (theme === 'dark' ? '#fff' : '#000')};
    }
  }
`;

export const Thumbnail = styled.div`
  flex: 1;

  @media (max-width: 960px) {
    display: none;
    width: 100%;
  }

  img {
    width: 100%;
  }
`;
